<template>
  <b-button :to="to" variant="primary">
    <feather-icon icon="PlusIcon"></feather-icon> Tambah
  </b-button>
</template>

<script>
import {BButton} from 'bootstrap-vue'
export default {
  props:{
    to:{
      required: false,
      type: [String,Object],
    }
  },
  name:'ButtonAdd',
  components:{
    BButton
  }
}
</script>

<style>

</style>