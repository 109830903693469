<template>
  <span class="padding-right-small">
    <b-button :disabled="disabled" :size="size" :to="to" :variant="`outline-${variant}`">
      <feather-icon :icon="featherIcon"></feather-icon> {{name}}
    </b-button>
  </span>
</template>

<script>
import {BButton} from 'bootstrap-vue'
export default {
  props:{
    to:{
      required: false,
      type: [String,Object],
    },
    featherIcon:{
      required: false,
      type: [String],
    },
    name:{
      required: false,
      type: [String],
    },
    size:{
      required: false,
      type: String,
      default: ''
    },
    variant:{
      type: String,
      default: 'primary'
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  name:'ButtonLink',
  components:{
    BButton
  }
}
</script>

<style lang="scss" scoped>
  .padding-right-small {
    padding-right: 3px;
  }
</style>