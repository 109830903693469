<template>
  <b-row>
    <b-col sm="12" md="6">
      <small class="padding">Tampilkan</small>
      <label>
        <b-form-select
          size="sm"
          v-model="ip.state.perPage"
          :options="ip.pageLengths"
        />
      </label>
      <small class="padding">Entri</small>
    </b-col>
    <b-col sm="12" offset-md="2" md="4" class="mb-1">
      <b-input-group>
        <b-form-input @input="ip.state.filter = search" v-model="search" type="search" placeholder="Cari..."></b-form-input>
        <b-input-group-append is-text>
          <b-icon :scale="0.8" icon="search"></b-icon>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col sm="12">
      <b-overlay :show="ip.busy" :opacity="1" spinner-variant="primary" variant="transparent">
        <b-table
          class="datatables"
          ref="datatables"
          striped
          hover
          responsive
          :items="ip.items"
          :fields="ip.fields"
          :busy="ip.busy"
          :current-page="ip.state.currentPage"
          :per-page="ip.state.perPage"
          :filter="ip.state.filter"
          :filter-ignored-fields="ip.state.filterIgnoredFields"
          :filter-included-fields="ip.state.filterIncludedFields"
          :no-provider-paging="isLocal"
          :no-provider-sorting="isLocal"
          :no-provider-filtering="isLocal"
          :sort-by="sortKey || '0'"
          :sort-desc="desc"
          :api-url="endpoint"
          show-empty
          no-sort-reset
        >
          <template v-for="slot in Object.keys($scopedSlots)" v-slot:[toCellName(slot)]="props">
            <slot v-bind="props" :name="slot" />
          </template>
          <template #empty>
            <h5>Tidak ada data.</h5>
          </template>
        </b-table>
      </b-overlay>
      <div v-if="ip.totalRows > 0">
        <b-row>
          <b-col sm="6" class="font-italic">
            <small class="padding">Menunjukkan {{ip.startRow}} ke {{ip.endRow}} dari {{ip.totalRows}}</small>
          </b-col>
          <b-col sm="6">
            <div class="float-right">
              <b-pagination
                v-model="ip.state.currentPage"
                :per-page="ip.state.perPage"
                :total-rows="ip.totalRows"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BTable,BPagination,BInputGroup,BFormInput,BInputGroupAppend,BIcon,BFormSelect,BOverlay } from 'bootstrap-vue'
import ItemsProvider from 'bvtnet-items-provider'
import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  props:{
    fields:{
      required: true,
      type: Object
    },
    endpoint:{
      required: true,
      type: String
    },
    desc:{
      type: Boolean,
      default: false
    },
    title:{
      type: String,
      default: ''
    },
    sortKey:{
      required: false,
      type: [String],
    },
    queryParams:{
      type: Object
    }
  },
  data(){
    let ip = new ItemsProvider({axios: axios, fields: this.fields})
    ip.pageLengths = [
      {text:"10",value:10},
      {text:"25",value:25},
      {text:"50",value:50},
      {text:"100",value:100}
    ]
    ip.state.perPage = 10
    ip.state.extraQuery = this.queryParams
    return {
      ip: ip,
      isLocal: false,
      search:'',
    }
  },
  computed:{
    isDark(){
      const {skin} = useAppConfig()
      return skin.value === 'dark' ? true : false
    }
  },
  components:{
    BTable,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BIcon,
    BFormSelect,
    BOverlay
  },
  methods:{
    toCellName (slot) {
      return `cell(${slot})`
    },
    refresh(){
      this.ip.state.extraQuery = this.queryParams
      this.$refs.datatables.refresh()
    },
  }
}
</script>

<style scoped lang="scss">
  .datatables {
    border-radius: 7px;
  }
  .padding {
    padding-left: 5px;
    padding-right: 5px;
  }
</style>