<template>
  <b-button @click="confirmDelete" variant="outline-danger" size="sm">
    <feather-icon icon="Trash2Icon"></feather-icon>
  </b-button>
</template>

<script>
import {BButton} from 'bootstrap-vue'
export default {
  props:{
    id:{
      required: true,
      type: [String,Number],
    },
    name:{
      required: false,
      type: String,
      default: ``
    }
  },
  name:'ButtonDelete',
  components:{
    BButton
  },
  methods:{
    confirmDelete(){
      this.$swal({
        title:`Konfirmasi Hapus Data!`,
        html:`Anda akan menghapus baris <b>${this.name}</b>. Setelah dihapus data tidak dapat dipulihkan kembali`,
        icon:`warning`,
        showCancelButton:true,
        cancelButtonText:`Tidak`,
        confirmButtonText:`Ya`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value) {
          this.$emit('ondelete', {id: this.id, name: this.name})
        }
      })
    }
  }
}
</script>

<style>

</style>